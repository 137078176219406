<template>
  <th-page-wrapper>
    <th-datatable
      ref="tableRef"
      sortable
      do-route-filters
      no-meta-check
      resource="documents"
      route-base="/documents/overview"
      show-search-filter
      show-operations
      multiple-select
      :search-filters="filtersList"
      :resource-limit="100"
      :resource-query="resourceQuery"
      :labels="labels"
      :locale="locale"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      :headers="headers"
      @loading-error="handleLoadingError"
      @selection-change="handleSelectionChange"
    >
      <template #actions class="actions">
        <el-button
          v-permissions="{ scopes: ['documents:send'] }"
          :disabled="!selectedDocuments.length"
          @click="bulkSendDocuments"
        >
          {{ $t('pages.documents.actions.bulk_send') }}
        </el-button>
        <el-button
          v-permissions="{ scopes: ['documents:export'] }"
          :disabled="!selectedDocuments.length"
          @click="handleBulkDownload"
        >
          {{ $t('pages.documents.actions.bulk_download') }}
        </el-button>
      </template>
      <template #operations="scope">
        <div class="flex justify-end">
          <a
            v-permissions="{ scopes: ['documents:export'] }"
            target="_blank"
            class="flex px-3 el-button--text-icon cursor-pointer"
            download
            @click.stop="handleDownload(scope.row)"
          >
            <el-icon class="text-lg">
              <Document />
            </el-icon>
          </a>
          <a
            v-permissions="{ scopes: ['documents:send'] }"
            class="flex px-3 el-button--text-icon cursor-pointer"
            @click.stop.prevent="handleSend(scope.row)"
          >
            <el-icon class="text-lg">
              <svgicon
                :src="require(`@/assets/icons/th-icon-email.svg`)"
                class="svg-icon"
              />
            </el-icon>
          </a>
          <a
            v-permissions="{ scopes: ['documents:export'] }"
            target="_blank"
            class="flex px-3 el-button--text-icon cursor-pointer"
            @click.stop.prevent="handlePrint(scope.row)"
          >
            <el-icon class="text-lg">
              <Printer />
            </el-icon>
          </a>
        </div>
      </template>
    </th-datatable>
    <document-send-modal />
  </th-page-wrapper>
</template>

<script setup>
import { ref, computed, watch, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import printJS from 'print-js'
import qs from 'qs'
import th from '@tillhub/javascript-sdk'
import emitter from 'tiny-emitter/instance'
import { formatDateTime, formatDateRange } from '@/utils/date'
import DocumentSendModal from './components/document-send-modal'

const EMPTY_CELL_TXT = '-'

const logException = inject('logException')
const ampli = inject('ampli')
const store = useStore()
const { t } = useI18n()

const route = useRoute()

const tableRef = ref(null)
const selectedDocuments = ref([])

const currentLocation = computed(
  () => store.getters['Config/getCurrentLocation']
)

const documentTypes = computed(() => ({
  PurchaseOrder: {
    value: 'PurchaseOrder',
    label: t('common.documents.types.purchase_order')
  },
  ConsignmentNote: {
    value: 'ConsignmentNote',
    label: t('common.documents.types.consignment_note')
  },
  Invoice: {
    value: 'Invoice',
    label: t('common.documents.types.invoice')
  },
  TransactionReceipt: {
    value: 'TransactionReceipt',
    label: t('common.documents.types.transaction_receipt')
  }
}))

const filtersList = ref([
  {
    name: 'documentType',
    type: 'select',
    label: t('pages.documents.overview.document_type'),
    placeholder: t('common.inputs.placeholders.select'),
    options: documentTypes.value,
    formatValue: (value) => documentTypes.value[value].label
  },
  {
    name: 'date',
    type: 'daterange',
    prop: ['start', 'end'],
    preventDefaultDates: true,
    label: t('pages.stock_movements.all.filters.daterange.label'),
    formatValue: (value) => formatDateRange(value),
    modifyFilter: ({ start, end }) => {
      // if end date is in the future, send now as the end date
      const now = new Date()
      const endDate = now < new Date(end) ? now.toISOString() : end
      return { start, end: endDate }
    },
    noFutureDates: true
  }
])

const labels = computed(() => ({
  table: {
    headers: {
      operations: t('pages.documents.overview.actions')
    }
  }
}))

const resourceQuery = computed(() => ({
  documentTypeGroup: 'Direct',
  ...(currentLocation.value ? { location: currentLocation.value } : {})
}))

const parsedQuery = computed(() => {
  const parsedQuery = (qs.parse(route.query) || {}).filter
  return parsedQuery || {}
})

const locale = computed(() => store.getters['Config/getLocale'])

const headers = computed(() => [
  {
    field: 'documentNumber',
    label: t('pages.documents.overview.document_number'),
    fallback: EMPTY_CELL_TXT,
    minWidth: 160,
    truncate: true
  },
  {
    field: 'documentType',
    label: t('pages.documents.overview.document_type'),
    fallback: EMPTY_CELL_TXT,
    minWidth: 120,
    truncate: true,
    formatter: (row) => documentTypes.value[row.documentType].label
  },
  {
    field: 'createdAt',
    label: t('pages.documents.overview.date_created'),
    minWidth: 120,
    truncate: true,
    formatter: (row) =>
      row.createdAt ? formatDateTime(row.createdAt) : EMPTY_CELL_TXT
  }
])

async function handleDownload({ id }) {
  try {
    const { data, contentType, filename } = await th.documents().download(id)
    const link = document.createElement('a')
    link.href = `data:${contentType};base64,${data}`
    link.download = filename
    link.click()
    ampli.eventWithBaseProps('documentDownload')
  } catch (error) {
    logException(error, {
      message: t('notifications.pdf.download.fail')
    })
  }
}

async function handleBulkDownload() {
  try {
    const selectedDocumentIds = selectedDocuments.value.map(
      (selectedDocument) => selectedDocument.id
    )
    const { data, contentType, filename } = await th
      .documents()
      .bulkDownload({ documentIds: selectedDocumentIds })
    const link = document.createElement('a')
    link.href = `data:${contentType};base64,${data}`
    link.download = filename
    link.click()
    ampli.eventWithBaseProps('documentBulkDownload')
  } catch (error) {
    logException(error, {
      message: t('notifications.pdf.download.fail')
    })
  }
}

function handleSend(row) {
  emitter.emit('email-preview', [row])
}

async function handlePrint({ id }) {
  try {
    const { data } = await th.documents().download(id)
    printJS({
      printable: data,
      type: 'pdf',
      base64: true
    })
    ampli.eventWithBaseProps('documentPrint')
  } catch (error) {
    logException(error, {
      message: t('notifications.pdf.download.fail')
    })
  }
}

function handleLoadingError(err) {
  logException(err, {
    trackError: false,
    message: t('common.error.action.read.multiple', {
      resources: t('pages.stock_movements.title')
    })
  })
}

function handleSelectionChange(value) {
  selectedDocuments.value = value
}

function bulkSendDocuments() {
  emitter.emit('email-preview', selectedDocuments)
}

watch(parsedQuery, () => tableRef.value?.refresh())
</script>
