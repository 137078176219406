<template>
  <th-modal
    show-inline
    name="email-preview"
    width="1200px"
    height="800px"
    :title="$t('pages.documents.modals.send_documents')"
    body-class="flex flex-col"
    @close="handleCancel"
  >
    <el-form ref="form" model="form" :rules="rules" class="w-1/2">
      <el-form-item
        :label="$t('common.resource.supplier.singular')"
        prop="supplier"
      >
        <el-select
          id="supplierId"
          v-model="form.supplierId"
          v-cancel-read-only
          v-loading="isLoadingSuppliers"
          :placeholder="$t('pages.documents.placeholders.select_suppliers')"
          clearable
          filterable
          allow-create
          class="w-full"
          @change="handlePartnerChange"
          @clear="form.supplierId = null"
        >
          <el-option
            v-for="supplier in suppliers"
            :key="supplier.id"
            :label="supplier.companyName"
            :value="supplier.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('common.forms.labels.recipient_email')"
        prop="recipients"
      >
        <el-select
          id="recipients"
          v-model="form.recipients"
          v-cancel-read-only
          clearable
          multiple
          filterable
          allow-create
          class="w-full"
          @clear="form.recipients = []"
          @change="handeEmailChange"
          @remove-tag="handleSingleEmailRemoved"
          @keyup.enter.space="handleRecipientsSelectEnterKey"
        >
          <el-option
            v-for="email in emails"
            :key="email"
            :label="email"
            :value="email"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <loading v-if="isLoadingPreview" class="mt-10" />
    <div v-else class="px-10 pt-6">
      <h1 class="pb-10">{{ subject }}</h1>
      <p v-for="line in body" :key="line">{{ line }}</p>
      <div class="pt-10">
        <div
          v-for="document in documents"
          :key="document.id"
          class="mb-4 last:mb-0 cursor-pointer"
        >
          <a
            class="el-button--text-icon"
            @click.stop="handleDownload(document.id)"
          >
            <el-icon class="text-4xl align-middle mr-4">
              <Document />
            </el-icon>
            <span class="align-middle">{{
              getDocumentFilename(document)
            }}</span>
          </a>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button @click="handleCancel">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>
      <el-button type="primary" :loading="isSending" @click="handleSave">
        {{ $t('common.interactions.buttons.send') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import Loading from '@/components/loading'

export default {
  components: {
    Loading
  },
  data() {
    return {
      form: {
        recipients: [],
        supplierId: null
      },
      emails: [],
      suppliers: [],
      isLoadingSuppliers: false,
      isLoadingPreview: false,
      isSending: false,
      documents: [],
      subject: '',
      body: []
    }
  },
  computed: {
    rules() {
      return {
        recipients: [{ validator: this.validateEmails }]
      }
    },
    documentTypes() {
      return {
        PurchaseOrder: this.$t('common.documents.types.purchase_order'),
        ConsignmentNote: this.$t('common.documents.types.consignment_note'),
        Invoice: this.$t('common.documents.types.invoice'),
        TransactionReceipt: this.$t(
          'common.documents.types.transaction_receipt'
        )
      }
    }
  },
  mounted() {
    this.$emitter.on('email-preview', this.fetchData)
  },
  beforeUnmount() {
    this.$emitter.off('email-preview')
  },
  methods: {
    async fetchData(documents) {
      try {
        this.isLoadingSuppliers = true
        this.documents = documents
        this.$thModal.show('email-preview')
        await Promise.all([this.fetchEmailPreview(), this.fetchSuppliers()])
      } catch (error) {
        this.$logException(error, {
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.supplier.singular')
          })
        })
      } finally {
        this.isLoadingSuppliers = false
      }
    },
    async fetchEmailPreview() {
      const email = await this.getEmailPreview()
      this.subject = email?.subject
      this.body = email?.body?.split('\n')
    },
    async fetchSuppliers() {
      const { data } = await th.suppliers().getAll()
      this.suppliers = [...data]
    },
    async handlePartnerChange() {
      try {
        this.isLoadingPreview = true
        const supplier = this.suppliers.find(
          (supplier) => supplier.id === this.form.supplierId
        )
        this.emails = supplier && supplier.email ? [supplier.email] : []
        this.form.recipients = [...this.emails]
      } catch (error) {
        this.$logException(error, {
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.supplier.singular')
          })
        })
      } finally {
        this.isLoadingPreview = false
      }
    },
    async getEmailPreview() {
      const documentIds = this.documents.map((document) => document.id)
      if (documentIds.length > 1) {
        const { data } = await th.documents().bulkPreview({ documentIds })
        return data
      }
      const { data } = await th.documents().preview(documentIds[0])
      return data
    },
    async handleDownload(id) {
      try {
        const { data, contentType, filename } = await th
          .documents()
          .download(id)
        const link = document.createElement('a')
        link.href = `data:${contentType};base64,${data}`
        link.download = filename
        link.click()
      } catch (error) {
        this.$logException(error, {
          message: this.$t('notifications.pdf.download.fail')
        })
      }
    },
    handleCancel() {
      this.$thModal.hide('email-preview')
      this.$emit('close')
    },
    handleSave() {
      this.validate(async (valid) => {
        if (!valid) return
        try {
          this.isSending = true
          const documentIds = this.documents.map((document) => document.id)
          const { recipients } = this.form
          if (documentIds.length > 1) {
            await th.documents().bulkSend({ recipients, documentIds })
            this.$ampli.eventWithBaseProps('documentBulkSend')
          } else {
            await th.documents().send(documentIds[0], { recipients })
            this.$ampli.eventWithBaseProps('documentSend')
          }

          this.$message({
            type: 'success',
            message: this.$t('common.interactions.send_email.success')
          })

          this.$emitter.emit('refresh-requested')
          this.$thModal.hide('email-preview')
          this.$emit('close')
        } catch (error) {
          this.$logException(error, {
            message: this.$t('common.error.action.update.single', {
              resource: this.$t('common.resource.documents.plural')
            })
          })
        } finally {
          this.isSending = false
        }
      })
    },
    validate(callback) {
      this.$refs['form'].validate(callback)
    },
    validateEmails(rule, value, callback) {
      if (this.form.recipients.length === 0) {
        return callback(new Error(this.$t('common.forms.rules.email_required')))
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const invalidEmails = this.form.recipients.filter((email) => {
        return !emailRegex.test(email)
      })
      if (invalidEmails.length > 0) {
        return callback(
          new Error(this.$t('common.forms.rules.email_format_invalid'))
        )
      }
      callback()
    },

    getDocumentFilename(document) {
      const type = this.documentTypes[document.documentType] || 'Unknown type'
      return `${type} #${document.documentNumber}`
    },

    handleRecipientsSelectEnterKey({ target }) {
      // That all is to fix UI issue when you have to click on the dropdown to actually add
      // the value you're entering. With this you can add the value just by pressing Enter or Space
      const input = target
      const value = input.value.trim()
      if (value === '') {
        return
      }
      this.form.recipients.push(value)
      input.value = ''
      input.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }
}
</script>
